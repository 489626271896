import React, { useState, useEffect, useRef } from 'react';
import './Language.css';
import html from '../assets/icons8-html-5-96.png';
import css from '../assets/icons8-css3-96.png';
import script from '../assets/icons8-javascript-96.png';
import react from '../assets/icons8-react-96.png';
import tailwind from '../assets/icons8-tailwindcss-96.png';
import sql from '../assets/icons8-git-96.png';
import photo from '../assets/icons8-adobe-photoshop-96.png';
import illustrator from '../assets/icons8-adobe-illustrator-96.png';
import premier from '../assets/icons8-adobe-premiere-pro-96.png';
import { faGears } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SkillImg from '../../Components/SkillImg';

export default function Language(){

    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);

    useEffect(() => {
      const handleScroll = () => {
        const elementTop = elementRef.current.getBoundingClientRect().top;
        const shouldDisplay = elementTop < 500 && elementTop > (-420);
        setIsVisible(shouldDisplay);
      };
      window.addEventListener('scroll', handleScroll);
      handleScroll();
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return(
        <div className={(isVisible)?"languageDiv language":'languageHidden language'} ref={elementRef}>
          <h1 className={(isVisible)?"languagesHeaderDiv":'languagesSlideOut'}><FontAwesomeIcon icon={faGears}  /> My Skills</h1>
            <div className="languagesDesc">
              <SkillImg delay={100} icon={html} text='HTML' isVisible={isVisible} />
              <SkillImg delay={200} icon={css} text='CSS3' isVisible={isVisible} />
              <SkillImg delay={300} icon={script} text='JavaScript' isVisible={isVisible} />
              <SkillImg delay={400} icon={react} text='React' isVisible={isVisible} />
              <SkillImg delay={500} icon={tailwind} text='TailWind' isVisible={isVisible} />
              <SkillImg delay={600} icon={sql} text='Git' isVisible={isVisible} />
              <SkillImg delay={700} icon={photo} text='PhotoShop' isVisible={isVisible} />
              <SkillImg delay={800} icon={illustrator} text='Illustrator' isVisible={isVisible} />
              <SkillImg delay={900} icon={premier} text='Premier Pro' isVisible={isVisible} />
            </div>
        </div>
    )
}