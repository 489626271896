import React, { useState } from 'react';
import { faBars, faCode, faGears, faBarsStaggered } from '@fortawesome/free-solid-svg-icons';
import { faUser, faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSpring, animated as a } from 'react-spring';
import './Header.css';

export default function Header() {

  const [display, setDisplay] = useState(false); 

  const springTop = useSpring({
    from: { transform: 'translateY(-100%)' },
    to: { transform: 'translateY(0)' },
    config: { tension: 150, friction: 15, }, 
    delay: 1000,
  }); 

  const scrollScreen = (id) => {
    const section = document.querySelector(id); 
    section.style.display = 'flex'; 
    section.scrollIntoView({behavior:'smooth', block:'start', inline:'start', offset: -4000}); 
    setDisplay(false);
  };

  const scrollToTop = () => {
    window.scrollTo(0,0);
    setDisplay(false);
  }

  const showMenu = () => {
    setDisplay(!display); 
  }

  return (
    <div>
      <a.div style={springTop} className='headerDiv'>
        <div className='headerInfo' onClick={scrollToTop}>
          <strong className='name'>Kelsey Jackson</strong>
        </div>
        <div className='navLinksDiv'>
          <span className='navLink' onClick={()=>{scrollScreen('.about')}}><FontAwesomeIcon icon={faUser}/> About</span>
          <span className='navLink' onClick={()=>{scrollScreen('.language')}}><FontAwesomeIcon icon={faGears}  /> Skills</span>
          <span className='navLink' onClick={()=>{scrollScreen('.projectsDiv')}}><FontAwesomeIcon icon={faCode}  /> Projects</span>
          <span className='navLink' onClick={()=>{scrollScreen('.contactDiv')}}><FontAwesomeIcon icon={faPaperPlane}  /> Contact</span>
        </div>
      </a.div>
      <a.div style={springTop} className='headerDivMobile'>
          <FontAwesomeIcon icon={(display)?faBarsStaggered:faBars} size='2x' onClick={showMenu}/>
            <div className='headerInfoMobile' onClick={scrollToTop}>
              <strong className='nameMobile'>Kelsey Jackson</strong>
            </div>
          <img src='https://i.ibb.co/FwTLQMS/creative.png' className='helloImgMobile' alt='creativityImage' />
      </a.div>
      <div className={(display)?'navLinksDivMobile':'displayNone'}>
          <span className='navLink' onClick={()=>{scrollScreen('.about')}}><FontAwesomeIcon icon={faUser}/> About</span>
          <span className='navLink' onClick={()=>{scrollScreen('.language')}}><FontAwesomeIcon icon={faGears}  /> Skills</span>
          <span className='navLink' onClick={()=>{scrollScreen('.projectsDiv')}}><FontAwesomeIcon icon={faCode}  /> Projects</span>
          <span className='navLink' onClick={()=>{scrollScreen('.contactDiv')}}><FontAwesomeIcon icon={faPaperPlane}  /> Contact</span>
      </div>
    </div>
  )
}