import './App.css';
import About from './Sections/About/About';
import Contact from './Sections/Contact/Contact';
import Footer from './Sections/Footer/Footer';
import Header from './Sections/Header/Header';
import Hello from './Sections/Hello.js/Hello';
import Language from './Sections/Languages/Language';
import Network from './Sections/Network/Network';
import Projects from './Sections/Projects/Projects';

function App() {
  return (
    <div className="App">
      <Header />
      <Hello />
      <About />
      <Network />
      <Language />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}
export default App;
