import { useSpring, animated as a } from 'react-spring';
import '../Sections/Languages/Language.css'

export default function SkillImg({delay, icon, text, isVisible}) {

    const springStyles = useSpring({
        transform: `translateX(${isVisible ? 0 : -200}px)`,
        opacity: isVisible ? 1 : 0,
        delay: delay,
      });

  return (
    <a.div style={springStyles}>
        <img src={icon} alt={icon} />
        <figcaption>{text}</figcaption>
    </a.div>
  )
}
