import { useState, useEffect, useRef } from 'react'
import './Footer.css'
import { PiHandsPrayingDuotone } from 'react-icons/pi'
import  aaron from '../assets/aaron.png'
import andrea from '../assets/andrea.png'  
import noel from '../assets/noel.png'
import { PiLinkedinLogo } from 'react-icons/pi'

export default function Footer() {

  const reviewA = useRef(null)
  const reviewB = useRef(null)
  const reviewC = useRef(null)

  const [reviewAScrollPosition, setReviewAScrollPosition] = useState(8)
  const [reviewBScrollPosition, setReviewBScrollPosition] = useState(0)
  const [reviewCScrollPosition, setReviewCScrollPosition] = useState(0)
  // const [reviewCount, setReviewCount] = useState(0)
  // const [timing, setTiming] = useState(5000)

  const cardsData = [
    {
      name: 'Andrea Ocarina Pérez',
      title: 'Host of AI Más Podcast, Founder of Refugees Code',
      image: andrea, 
      review: 'Kelsey possesses exceptional time management skills, despite having to manage many different tasks Kelsey demonstrates a remarkable dedication to the success of his mentees. He goes the extra mile to provide additional resources, offers personalized guidance, and creates a supportive learning environment.One of his notable qualities is his proactive approach to staying up-to-date with new coding trends. He engages in self-directed learning and takes the initiative to build personal projects to deepen his knowledge and skill set. This commitment to continuous improvement makes him an invaluable asset to our team and allows him to guide his students through the ever-evolving landscape of coding effectively. Kelsey\'s enthusiasm for his work and eagerness to embrace new opportunities is inspiring. He approaches each challenge with a positive mindset and an unwavering determination to succeed. I am confident that his passion for coding and his commitment to growth will lead him to excel in his future career endeavors.',
      link: 'https://www.linkedin.com/in/andreaocarina/',
      reference: reviewA,
      scrollPosition: reviewAScrollPosition,
    },
    {
      name: 'Aaron Venema',
      title: 'Software Engineer',
      image: aaron, 
      review: 'I had the opportunity to collaborate with Kelsey on projects while studying web development at Mimo. In addition to being very friendly and a team player, Kelsey has a deep understanding of JavaScript principles, React, and APIs. He was always eager to find ways to improve our projects, whether it be an additional feature, a neat design, or squashing those pesky bugs. I\'m confident Kelsey\'s technical abilities and his interpersonal skills would make him a great fit for any role.',
      link: 'https://www.linkedin.com/in/aaron-venema-a4797354/',
      reference: reviewB,
      scrollPosition: reviewBScrollPosition,
    },
    {
      name: 'Noel Guillen Blas',
      title: 'Front-End Developer',
      image: noel, 
      review: 'I had the pleasure of having Kelsey during my time at Mimo Bootcamp, and I was consistently impressed with his dedication, expertise, and willingness to help. Kelsey was always available to help me with my coding problems, whether it was during class, after hours, or even on weekends. He was always patient and understanding, and he took the time to explain concepts in a way that I could understand. I appreciated that Kelsey was always willing to share tips and videos that he had found helpful. In addition to his technical expertise, Kelsey was also a great advisor. He is a dedicated and knowledgeable mentor who is committed to helping students succeed and I highly recommend him.',
      link: 'https://www.linkedin.com/in/noel-guillen-blas-b63353257/', 
      reference: reviewC,
      scrollPosition: reviewCScrollPosition,
    },
  ]

  useEffect(() => {
    const handleScroll = () => {
      setReviewAScrollPosition(reviewA.current.getBoundingClientRect().left)
      setReviewBScrollPosition(reviewB.current.getBoundingClientRect().left)
      setReviewCScrollPosition(reviewC.current.getBoundingClientRect().left)
      // reviewA.current.getBoundingClientRect().left < 20 && reviewA.current.getBoundingClientRect().left > 0 && setReviewCount(0)
      // reviewB.current.getBoundingClientRect().left < 20 && reviewB.current.getBoundingClientRect().left > 0 && setReviewCount(1)
      // reviewC.current.getBoundingClientRect().left < 20 && reviewC.current.getBoundingClientRect().left > 0 && setReviewCount(2)
    }
    document.querySelector('.recommendations').addEventListener('scroll', handleScroll)
    return () => {
      document.querySelector('.recommendations').removeEventListener('scroll', handleScroll)
    }
  }, [])

  const scrollScreen = (id) => {
    const section = document.querySelector(id); 
    section.scrollIntoView({behavior:'smooth', block:'nearest', inline:'nearest', offset: -100}); 
  };

  // useEffect(()=>{
  //   const interval = setInterval(()=>{
  //     setReviewCount(prev => prev + 1)
  //   }, timing)

  //   return()=>{
  //     clearInterval(interval)
  //   }
  // }, [timing])

  // useEffect(()=>{
  //   console.log(`review${reviewCount}`)
  //   reviewCount > cardsData.length - 1 && setReviewCount(0)
  //   scrollScreen(`.review${reviewCount === 3 ? 2 : reviewCount}`)
  // }, [reviewCount, cardsData.length])

  // useEffect(()=>{
  //   reviewAScrollPosition < 20 && reviewAScrollPosition > 0 && setReviewCount(0)
  // }, [reviewAScrollPosition])
  

  return (
    <div className='footerDiv'>
      <div className='outerWrapper'>
        <div className="recommendations">
          <div className='cardsDiv'> 
            {
              cardsData.map((item, index)=>{
                return <div className={`recommendationsCard review${ index }`} ref={ item.reference } key={ index }>
                <img src={ item.image } alt='avatar' className='recommendationsAvatar' />
                <div className='recommendationsInfo'>
                  <p className='recommendationsTitle'>{ item.name }, <i>{ item.title }</i></p>
                  <a href={ item.link } style={{cursor: 'pointer', zIndex: '4', fontSize: '20px'}}> <PiLinkedinLogo /> </a>
                  <p className='review'>{ item.review }</p>
                </div>
            </div>
              })
            }
          </div>
        </div>
        <div className='threeDots'>
          {
            cardsData.map((item, index) =>{
              return <div className='dot' onClick={()=>{
                scrollScreen(`.review${ index }`);
                // setReviewCount(index)
                // setTiming(15000)
                // setTimeout(()=>{
                //   setTiming(5000)
                // }, 16000)
              }} style={{
                backgroundColor: item.scrollPosition < 20 && item.scrollPosition > 0  ? 'white' : 'var(--blue)'
              }}
              key={index}></div>
            })
          }
        </div>
      </div>
      <div className='thankYouDiv'>
          < PiHandsPrayingDuotone className='thankYouIcon' />
          <h1>Thanks for Visiting!</h1>
          < PiHandsPrayingDuotone className='thankYouIcon' />
      </div>
    </div>
  )
}