import React, { useState, useEffect, useRef } from 'react';
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/free-solid-svg-icons';
import { useForm, ValidationError } from '@formspree/react';
import './Contact.css';
import { useSpring, animated as a } from 'react-spring';
import gitHub from '../assets/icons8-github-94.png';
import linkedIn from '../assets/icons8-linkedin-96.png';
import coffee from '../assets/buymeacoffee.png';
import { GiFireworkRocket } from 'react-icons/gi';

export default function Contact() {

  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const elementTop = elementRef.current.getBoundingClientRect().top;
      const shouldDisplay = elementTop < 500 && elementTop > (-1000);
      setIsVisible(shouldDisplay);
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const springLeft = useSpring({
    transform: `translateX(${isVisible ? 0 : -200}px)`,
    opacity: isVisible ? 1 : 0,
    delay: 100,
  });
  const springRight = useSpring({
    transform: `translateX(${isVisible ? 0 : 200}px)`,
    opacity: isVisible ? 1 : 0,
    delay: 300,
  });

  const [state, handleSubmit] = useForm("xzbqaplb");
  if (state.succeeded) {
      return ( 
        <div className='contactDiv' ref={elementRef}>
        <h1 className={(isVisible)?'contactHeader':'contactHeaderHidden'}><FontAwesomeIcon icon={faPaperPlane}  /> Contact Me</h1>
        <div className='contactFormDiv'>
          <a.div style={springLeft} className='contactInfo'>
              <div className='contactLinks'>
                  <a style={{color:'black'}} href='https://www.linkedin.com/in/kelsey-jackson-22b098256/'>
                  <img src={linkedIn} alt='linkedIn' className='linkedInIcon'/>
                    <figcaption>LinkedIn</figcaption>
                  </a>
                  <a style={{color:'black'}} href='https://github.com/kelso540'>
                    <img src={gitHub} alt='gitHub' className='gitHubIcon'/>
                    <figcaption>GitHub</figcaption>
                  </a>
                  <a style={{color:'black'}} href='https://www.buymeacoffee.com/jacksonchiw'>
                    <img src={coffee} alt='Buy me a coffee' className='buyMeACoffee'/>
                    <figcaption>Support</figcaption>
                  </a>
              </div>
              <a href="mailto:kelsojacks@outlook.com" className='contactLink contactMeLink'><FontAwesomeIcon icon={faEnvelopeOpenText} /> Send me an Email!</a>
          </a.div>
          <a.form style={springRight} className='contactForm' onSubmit={handleSubmit}>
            <h2>Contact Form</h2>
            <label htmlFor="email">Email Address</label>
            <input id="email" type="email" name="test" placeholder='example@email.com' />
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" placeholder='Type your message here!' />
            <div className='rocketDiv'>
              <GiFireworkRocket style={{rotate: '270deg', marginRight: '20px'}} className='rocket'/> 
                Submitted! 
              <GiFireworkRocket style={{marginLeft: '20px'}} className='rocket'/>
            </div>
          </a.form>
        </div>
      </div> 
    );
  }

  return (
    <div className='contactDiv' ref={elementRef}>
      <h1 className={(isVisible)?'contactHeader':'contactHeaderHidden'}><FontAwesomeIcon icon={faPaperPlane}  /> Contact Me</h1>
      <div className='contactFormDiv'>
        <a.div style={springLeft} className='contactInfo'>
            <div className='contactLinks'>
                <a style={{color:'black'}} href='https://www.linkedin.com/in/kelsey-jackson-22b098256/'>
                <img src={linkedIn} alt='linkedIn' className='linkedInIcon'/>
                  <figcaption>LinkedIn</figcaption>
                </a>
                <a style={{color:'black'}} href='https://github.com/kelso540'>
                  <img src={gitHub} alt='gitHub' className='gitHubIcon'/>
                  <figcaption>GitHub</figcaption>
                </a>
                <a style={{color:'black'}} href='https://www.buymeacoffee.com/jacksonchiw'>
                  <img src={coffee} alt='Buy me a coffee' className='buyMeACoffee'/>
                  <figcaption>Support</figcaption>
                </a>
            </div>
            <a href="mailto:kelsojacks@outlook.com" className='contactLink contactMeLink'><FontAwesomeIcon icon={faEnvelopeOpenText} /> Send me an Email!</a>
        </a.div>
        <a.form style={springRight} className='contactForm' onSubmit={handleSubmit}>
          <h2>Contact Form</h2>
          <label htmlFor="email">Email Address</label>
          <input id="email" type="email" name="email" placeholder='example@email.com' />
          <ValidationError prefix="Email" field="email" errors={state.errors} />
          <label htmlFor="message">Message</label>
          <textarea id="message" name="message" placeholder='Type your message here!' />
          <ValidationError prefix="Message" field="message" errors={state.errors} />
          <button type="submit" disabled={state.submitting}>Submit</button>
        </a.form>
      </div>
    </div>
  )
}
