import React, { useState, useEffect } from 'react';
import { useSpring, animated as a } from 'react-spring';
import './Hello.css'
import logo from '../assets/selfie.png'

export default function Hello() {

  const [count, setCount] = useState(0); 
  const [flip, setFlip] = useState(false);
  const [display, setDisplay] = useState(true); 
  const attributes = [
      {id: 0, key: 'Developer'},
      {id: 1, key: 'Self-driven'},
      {id: 2, key: 'Passionate'},
      {id: 3, key: 'Creative'}, 
      {id: 4, key: 'Artist'},
  ];

  const rotateSpin = useSpring({
    to: { transform: flip ? 'rotateX(-360deg)' : 'rotateX(0deg)' },
  });

  useEffect(() => {
    const interval = setInterval(() => { 
      setCount(prev=>prev+1);
      setFlip(!flip);
      (count >= attributes.length-1)&&setCount(0); 
    }, 3000);
    return () => clearInterval(interval);
  }, [count, flip, attributes.length]);

  const springOut = useSpring({
    from: { opacity: 1 },
    to: { opacity: 0 },
    config: { tension: 80, friction: 15, }, 
    delay: 1000, 
  }); 

  useEffect(() => {
    setTimeout(() => { 
        setDisplay(false);
    }, 1200);
  }, []);

  const springLeft = useSpring({
    from: { transform: 'translateX(-100%)', opacity: 0, },
    to: { transform: 'translateX(0)', opacity: 1, },
    config: { tension: 150, friction: 15, }, 
    delay: 1500,
  }); 

  const springRight = useSpring({
    from: { transform: 'translateX(100%)', opacity: 0, },
    to: { transform: 'translateX(0)', opacity: 1, },
    config: { tension: 150, friction: 15, }, 
    delay: 1500,
  });  

  return(
    <div className='helloDiv'>
        {display &&
            <a.div style={springOut} className='welcome'>
                <h1>WELCOME!</h1>
            </a.div>
        }
        <div className='helloMainDiv'>
          <a.div style={springRight} className='helloImgDiv'>
              <img src={logo} className='helloImg' alt='avatar' />
          </a.div>
          <a.div style={springLeft}>
              <div className='nameAndImageMobile'>
                  <h1 className='head'>Hello, I am Kelsey</h1> 
              </div>
              <div className='desc'>
                <h3>I am 
                  {attributes[count].id === 0 && ' a'}
                  {attributes[count].id === 4 && ' an'}
                </h3>
                <a.h2 style={rotateSpin}>{attributes[count].key}</a.h2>
              </div>
              <div className='contactLinkDiv'> 
                <a href="mailto:kelsojacks@outlook.com" className='btn'>mail</a>
              </div>
          </a.div> 
      </div>
    </div>
  )
}