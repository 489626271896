import React, {useEffect, useState, useRef} from 'react';
import './Sections.css'; 

export default function WebsiteCard({href, src, title, delay, direction, boolean}) {

  const [isVisible, setIsVisible] = useState(false);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const elementTop = elementRef.current.getBoundingClientRect().top;
      const shouldDisplay = elementTop < 500 && elementTop > (-420);
      setIsVisible(shouldDisplay);
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <a href={href} className='anchor' style={ isVisible ? {
      transform: `translateX(0px)`,
      opacity: 1,
      delay: delay,
      marginBottom: boolean ? '30px' : 'auto',
    } : {
      transform: `translateX(${direction}px)`,
      opacity: 0,
      delay: delay,
      marginBottom: boolean ? '30px' : 'auto',
    }}>
    <div className='card' ref={elementRef}>
        <h2 className='cardTitle'>{title}</h2>
        <img src={src} alt={title}/>
    </div>
    </a>
  )
}
