import React, { useState, useEffect, useRef } from 'react';
import './About.css';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function About() {
  const [isVisible, setIsVisible] = useState(false);
  const [scroll, setScroll] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const elementTop = elementRef.current.getBoundingClientRect().top;
      const newScroll = Math.max(0, window.scrollY * 0.2);
      setScroll(newScroll);
      const shouldDisplay = elementTop < 500 && elementTop > (-420);
      setIsVisible(shouldDisplay);
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

    return(
        <div className={(isVisible)?'aboutDiv about':'aboutHidden about'} ref={ elementRef }>
          <h1><FontAwesomeIcon icon={ faUser } /> About Me </h1>
            <div className='aboutHeaderDiv' style={{backgroundPosition:`0 -${ scroll }px`}}>
                <div className='aboutFlex'>
                    <h3>
                        I am a results-oriented front-end web developer with a deep passion for leveraging code to 
                        unlock creativity. My commitment to self-learning allows me to constantly evolve and stay 
                        up-to-date with the latest industry trends. I aim to create a positive and collaborative 
                        environment that brings out the best in myself and those around me. My genuine kindness 
                        and unwavering politeness contribute to creating a harmonious working atmosphere. I believe 
                        in the power of teamwork and value the unique perspectives that each individual brings to the table. 
                        I strive to ensure a seamless and enjoyable working experience for everyone 
                        involved. I am confident that my technical prowess, dedication to continuous improvement, and 
                        ability to foster collaboration make me an ideal candidate to help your organization 
                        achieve its web development goals.
                    </h3>
                </div>
            </div>
        </div>
    )
}