import React, {useState, useEffect, useRef} from 'react';
import WebsiteCard from './WebsiteCard';  
import weather from '../Sections/assets/Screenshot 2023-03-18 154649.png';
import trivia from '../Sections/assets/Trivia-Screenshot.png';
import './Sections.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';

export default function HTMLProjects() {

    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef(null);
  
    useEffect(() => {
      const handleScroll = () => {
        const elementTop = elementRef.current.getBoundingClientRect().top;
        (elementTop < window.innerHeight)?setIsVisible(true):setIsVisible(false);
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

  return (
    <div className='htmlDiv' ref={elementRef}>
      <h1 className={(isVisible)?'htmlHeader':'htmlHeaderHidden'}><FontAwesomeIcon icon={faCode}  /> HTML5 Projects</h1>
      <div className='htmlProjectsDiv'>
        <WebsiteCard href='https://trivia-challenge-site.netlify.app/' src={trivia} title='Trivia Challenge' delay={500} direction={-200} boolean={true} />
        <WebsiteCard href='https://local-weather-project.netlify.app/' src={weather} title='Local Weather' delay={600} direction={200} boolean={false} />
      </div>
    </div>
  )
}
