import React, { useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode } from '@fortawesome/free-solid-svg-icons';
import './Projects.css';
import movie from '../assets/Screenshot 2023-03-18 150128.png'; 
import meal from '../assets/Screenshot 2023-03-18 151229.png';
import game from '../assets/Screenshot 2023-03-18 154010.png'; 
import mars from '../assets/Screenshot 2023-03-18 154322.png';
import WebsiteCard from '../../Components/WebsiteCard';
import HTMLProjects from '../../Components/HTMLProjects';

export default function Projects() {

  const canvasText = '</canvas>'; 

  const elementRef = useRef(null);
  const robotRef = useRef(null);

  const [isVisible, setIsVisible] = useState(false);
  const [isRobotVisible, setIsRobotVisible] = useState(false);
  const [play, setPlay] = useState('paused'); 
  const [height, setHeight] = useState(0);
  const [border, setBorder] = useState('none');
  const [animation, setAnimation] = useState('none');
  const [top, setTop] = useState(50);

  useEffect(() => {
    const handleScroll = () => {
      const elementTop = elementRef.current.getBoundingClientRect().top;
      const robotTop = robotRef.current.getBoundingClientRect().top;
      const shouldDisplay = elementTop < 500 && elementTop > (-1020);
      const shouldRobotDisplay = robotTop < 500 && robotTop > (-420);
      setIsVisible(shouldDisplay);
      setIsRobotVisible(shouldRobotDisplay);
    };
    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(()=>{
      const changeRobot = ()=>{
        setPlay('running');
        setHeight(10);
        setBorder('solid 1px var(--black)');
        setAnimation('rotateEars 1s infinite linear');
        setTop(25);
      };
  
      const returnToDefault = ()=>{
        setPlay('paused');
        setHeight(0);
        setBorder('none');
        setAnimation('none');
        setTop(50);
      };
  
      document.querySelector('.game-website-link').addEventListener('mouseover', changeRobot); 
      document.querySelector('.game-website-link').addEventListener('mouseout', returnToDefault);
  
      return ()=>{
          document.querySelector('.game-website-link').removeEventListener('mouseover', changeRobot);
          document.querySelector('.game-website-link').removeEventListener('mouseout', returnToDefault); 
      };
    }, []);

  return (
    <div className='projectsDiv' ref={elementRef}>
      <div className='reactDiv'>
        <h1 className={(isVisible)?'reactHeader':'reactHeaderHidden'}><FontAwesomeIcon icon={faCode}  /> React Group Projects</h1>
        <div className='reactProjectsDiv'>
          <WebsiteCard href='https://voluble-manatee-c07f1b.netlify.app/' src={movie} title='CineTrail' delay={500} direction={-200} boolean={ false } />
          <WebsiteCard href='https://sweet-nasturtium-79d138.netlify.app/' src={meal} title='The Meal DB' delay={600} direction={200} boolean={ false } />
        </div>
        <h1 className={(isVisible)?'reactHeader soloProjectsLowerDiv':'reactHeaderHidden'}><FontAwesomeIcon icon={faCode}  /> React Solo Projects</h1>
        <div className='reactProjectsDiv'>
          <WebsiteCard href='https://free-games-project.netlify.app/' src={game} title='All Games For Free' delay={700} direction={-200} boolean={ false } />
          <WebsiteCard href='https://tangerine-choux-737c8e.netlify.app/' src={mars} title='Our Universe' delay={800} direction={200} boolean={ false } />
        </div>
      </div>

      <HTMLProjects />

      <div className={(isRobotVisible)?'HTMLCanvasDiv':'HTMLCanvasDivHidden'} ref={robotRef}>
        <h1>Try my {canvasText} games <a href='https://dynamic-longma-5471af.netlify.app/' className='game-website-link'>HERE</a>!</h1>
        <a href='https://dynamic-longma-5471af.netlify.app/' className='robotIconSmall'>
          <div className='hat'>
              <div className='hatRod'>
                  <div className='rodBall' style={{
                      animation: `pulseColor 0.5s infinite linear`, 
                      animationPlayState: `${ play }`
                  }}></div>
              </div>
          </div>
          <div className='eyes'>
              <div className='eye'>
                  <div className='eyeBall'></div>
              </div>
              <div className='eye'>
                  <div className='eyeBall'></div>
              </div>
          </div>
          <div className='earL' style={{
              animation: `${ animation }`,
              top: `${ top }%`,
          }}></div>
          <div className='earR' style={{
              animation: `${ animation }`, 
              top: `${ top }%`,
          }}></div>
          <div className='nose'></div>
          <div className='mouth'>
              <div className='teethVertical' style={{
                  height: `${ height }px`, 
                  borderTop: `${ border }`, 
              }}></div>
              <div className='tooth'></div>
              <div className='tooth'></div>
              <div className='tooth'></div>
              <div className='tooth'></div>
              <div className='tooth'></div>
              <div className='tooth'></div>
          </div>
        </a>
      </div>
    </div>
  )
}
