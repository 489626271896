import React, {useState, useEffect, useRef} from 'react';
import './Network.css';
import engineer from '../assets/icons8-center-96.png';
import business from '../assets/icons8-meetings-96.png';
import auto from '../assets/icons8-services-96.png';
import draw from '../assets/icons8-drawing-96.png';
import avatar from '../assets/icons8-linkedin-96.png';

export default function Network() {

    const elementRef = useRef(null); 

    const [isVisible, setIsVisible] = useState(false);
    const [scroll, setScroll] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
          const elementTop = elementRef.current.getBoundingClientRect().top;
          const newScroll = window.scrollY * 0.23;
          setScroll(newScroll);
          const shouldDisplay = elementTop < 500 && elementTop > (-520);
          setIsVisible(shouldDisplay);
        };
        window.addEventListener('scroll', handleScroll);
        handleScroll();
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return (
        <div className={(isVisible)?'snap networkDiv':'snap networkHidden'} ref={elementRef}>
            <h2 className={(isVisible)?'networkHead':'networkSlideOut'}>Lets Connect!</h2>
            <div className='networkIcons'>
                <img className='imgB' style={{ 
                    transform: `rotate(${ scroll }deg)`,
                    left: `${ scroll }px`,
                    bottom: `${ scroll }px`
                }} src={engineer} alt='engineer' />
                <img className='imgC' style={{ 
                    transform: `rotate(${ scroll }deg)`,
                    left: `${ scroll }px`,
                    top: `${ scroll }px`
                }} src={business} alt='business' />
                <img className='imgD' style={{ 
                    transform: `rotate(${ scroll }deg)`,
                    right: `${ scroll }px`,
                    top: `${ scroll }px`
                }} src={auto} alt='auto' />
                <img className='imgE' style={{ 
                    transform: `rotate(${ scroll }deg)`,
                    right: `${ scroll }px`,
                    bottom: `${ scroll }px`
                }} src={draw} alt='draw' />
                <div onClick={() => window.open('https://www.linkedin.com/in/kelsey-jackson-22b098256/')}>
                    <img className='imgF' src={avatar} alt='avatar' />
                </div>
            </div>
        </div>
    )
}
